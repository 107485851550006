<template>
  <div class="info-wrapper">
    <!-- <el-image :key="index" v-for="(path, index) in combinedDisplayImageList" fit="contain" :src="path"></el-image> -->
    <DefaultImg :key="index" v-for="(path, index) in combinedDisplayImageList" :src="path"></DefaultImg>
    <div class="name-struct">
      <span class="name">
        {{ data.name }}
      </span>
      <span class="struct"> ({{ combinedStructName || '暂无' }}) </span>
    </div>
  </div>
</template>

<script>
import { get, flatMapDeep } from 'lodash'
import DefaultImg from '@/views/components/defaultImg'

export default {
  components: {
    DefaultImg
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    combinedDisplayImageList({ data }) {
      try {
        const productPrototypeList = data.productPrototypeList || []
        return productPrototypeList.map((item) => {
          return get(item, 'styleList[0].styleDisplayImageList[0].displayImagePath')
        })
      } catch {
        return [{}]
      }
    },

    combinedStructName({ data }) {
      try {
        return flatMapDeep(data.productPrototypeList, (n) => {
          return [n.styleList]
        })
          .map(({ name }) => name)
          .join('-')
      } catch {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-wrapper {
  display: flex;
  align-items: center;
  .el-image {
    width: 100px;
    height: 100px;
  }
  .el-image + .el-image {
    margin-left: 10px;
  }
  .name-struct {
    margin-left: 10px;
  }
}
</style>