<template>
  <div class="info-wrapper">
    <!-- <el-image fit="contain" :src="displayImage"></el-image> -->
    <DefaultImg :src="displayImage"></DefaultImg>
    <div class="name-struct">
      <span class="name">
        {{ data.chineseName }}
      </span>
      <span class="struct"> ({{ structName || '暂无' }}) </span>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash'
import DefaultImg from '@/views/components/defaultImg'

export default {
  components: {
    DefaultImg
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    displayImage({ data }) {
      try {
        return get(data, 'styleList[0].styleDisplayImageList[0].displayImagePath')
      } catch {
        return ''
      }
    },

    structName({ data }) {
      try {
        return data.styleList.map(({ name }) => name).join('-')
      } catch {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-wrapper {
  display: flex;
  align-items: center;
  .el-image {
    width: 100px;
    height: 100px;
  }
  .name-struct {
    margin-left: 10px;
  }
}
</style>