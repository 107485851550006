<template>
  <BaseDialog maxHeight="60vh" width="1200px" :title="title" :dialogVisible.sync="dialog">
    <div class="content mb20">原型</div>
    <component :data="data" :is="option.infoComponentName" />
    <avue-crud ref="crud" class="flex-one-page" :option="option.tableOption" :data="filterData" :page="tablePage">
      <template #bottomBoardPreview="{ row }">
        <DefaultImg :src="bottomBoardListPreview(row)" :content="row.chineseName"></DefaultImg>
      </template>
    </avue-crud>
  </BaseDialog>
</template>


<script>
import DefaultImg from '@/views/components/defaultImg'

import SingleProto from './singleProto'
import CombinationProto from './combinationProto'

import avueCrud from '@/mixins/avueCrud'
import { commonFromMixin } from '@/mixins'
import { getOption } from '../const'

export default {
  components: {
    DefaultImg,

    SingleProto,
    CombinationProto
  },

  mixins: [avueCrud({ isInit: false }), commonFromMixin],

  props: {
    data: {
      type: Object,
      required: true
    },

    type: {
      type: String,
      default: 'default'
    }
  },

  computed: {
    bottomBoardList({ data }) {
      return data.bottomBoardList || []
    },

    filterData({ bottomBoardList, searchForm }) {
      const { nickName } = searchForm
      if (!nickName) return bottomBoardList
      return bottomBoardList.filter((item) => (item.nickName || '').indexOf(nickName) >= 0)
    },

    bottomBoardListPreview() {
      return (row) => {
        try {
          return row.previewImagePath
        } catch {
          return []
        }
      }
    },

    option({ type }) {
      return getOption(type)
    }
  }
}
</script>

<style lang="scss" scoped>
.info-wrapper {
  display: flex;
  align-items: center;
  .el-image {
    width: 100px;
    height: 100px;
  }
  .name-struct {
    margin-left: 10px;
  }
}
</style>